import "$styles/index.css";
import "$styles/syntax-highlighting.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

document.addEventListener("DOMContentLoaded", function () {
  // Mobile menu functionality
  const menuButton = document.querySelector("#mobile-menu-button");
  const closeButton = document.querySelector("#mobile-menu-close-button");
  const mobileMenu = document.querySelector("#mobile-menu");

  menuButton.addEventListener("click", function () {
    mobileMenu.classList.toggle("hidden");
  });

  closeButton.addEventListener("click", function () {
    mobileMenu.classList.toggle("hidden");
  });
});
